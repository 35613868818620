import { ResponsiveBar } from '@nivo/bar';
import { Dictionary } from 'common/entities';
import { IBaseChartProps } from '../entities';
import { colorPalette, nivoTheme } from '../nivoTheme';
import Styles from './Styles.module.scss';

export default function StackedBarChart(props: IBarChartProps) {
    const colorMap: Dictionary<string> = props.fieldList?.reduce(
        (acc, curr, i) => ({ ...acc, [curr]: (props?.customColor ?? colorPalette)[i] }),
        {}
    );

    return (
        <>
            <div
                className={[Styles.ChartContainer, props.className].join(' ')}
                style={{ height: props.height || '100%', width: props.width || '100%' }}
            >
                <ResponsiveBar
                    data={props.data}
                    keys={props.fieldList}
                    groupMode={props?.isGrouped ? 'grouped' : 'stacked'}
                    indexBy={props.indexBy ? props.indexBy : 'category'}
                    margin={props?.margin ?? { top: 20, right: 40, bottom: 40, left: 40 }}
                    padding={props?.padding ? props?.padding : 0.3}
                    layout={props.isHorizontal ? 'horizontal' : 'vertical'}
                    maxValue={props?.maxValue}
                    borderRadius={props?.borderRadius}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={d => {
                        const color = colorMap[d.id];
                        if (color) {
                            return color;
                        }
                        const randomIndex = Math.floor(Math.random() * (props?.customColor ?? colorPalette).length);
                        return (props?.customColor ?? colorPalette)[randomIndex];
                    }}
                    theme={{ ...nivoTheme, ...props?.themeConfig }}
                    axisTop={props?.axisTop ?? null}
                    axisRight={props?.axisRight ?? null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                    }}
                    axisLeft={
                        props?.axisLeft ?? {
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                        }
                    }
                    label={props?.label}
                    labelSkipWidth={props?.labelSkipWidth ?? 30}
                    labelSkipHeight={props?.labelSkipHeight ? props?.labelSkipHeight : 15}
                    // labelTextColor={{ theme: "background" }}
                    role="application"
                    legends={props?.legends ? props?.legends : []}
                />
            </div>
        </>
    );
}

export interface IBarChartProps extends IBaseChartProps {
    data?: Array<Dictionary<any>>;
    fieldList?: Array<string>;
    isHorizontal?: boolean;
    isGrouped?: boolean;
    maxValue?: number;
    borderRadius?: number;
    themeConfig?: {};
    customColor?: string[];
    label?: any;
    padding?: number;
    margin?: { top: number; right: number; bottom: number; left: number };
    labelSkipWidth?: number;
    labelSkipHeight?: number;
    axisLeft?: {
        tickSize: number;
        tickPadding: number;
        tickRotation: number;
        legend?: string;
        truncateTickAt: number;
        legendPosition?: any;
        legendOffset?: number;
        format?: (value: any) => string
    };
    axisTop?: {
        tickSize: number;
        tickPadding: number;
        tickRotation: number;
        legend: string;
        truncateTickAt: number;
        legendPosition: any;
        legendOffset: number;
    };
    axisRight?: {
        tickSize: number;
        tickPadding: number;
        tickRotation: number;
        legend: string;
        truncateTickAt: number;
        legendPosition: any;
        legendOffset: number;
    };
    axisBottom?: {
        tickSize: number;
        tickPadding: number;
        tickRotation: number;
        legend: string;
        truncateTickAt: number;
        legendPosition: any;
        legendOffset: number;
    };
    keys?: string[];
    indexBy?: string;
    legends?: any;
}
